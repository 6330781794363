import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';


const routes: Routes = [
  {
    path: 'tot',
    loadChildren: () => import('./views/menu/menu.module')
      .then(m => m.MenuModule),
  },
  { path: '', redirectTo: 'tot', pathMatch: 'full' },
  { path: '**', redirectTo: 'tot' }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
