import { DataBases } from '../domains/dataBases';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class DataBasesService {

  private pathPlc = [AppConfig.api.URL, 'dataBases'].join('/');

  constructor(private http: HttpClient) { }

  testDataBaseConnection(dbConnection: DataBases) {
    return this.http.post(`${this.pathPlc}/testDataBaseConnection`, dbConnection);
  }

  createDataBaseConnection(dbConnection: DataBases) {
    return this.http.post(`${this.pathPlc}/createDataBaseConnection`, dbConnection);
  }

  getAllDataBasesConnections() {
    return this.http.get(`${this.pathPlc}/getAllDataBasesConnections`);
  }

  deleteDataBaseConnection(dbConnection: DataBases) {
    return this.http.post(`${this.pathPlc}/deleteDataBaseConnection`, dbConnection);
  }

  updateDataBaseConnection(dbConnection: DataBases) {
    return this.http.put(`${this.pathPlc}/updateDataBaseConnection`, dbConnection);
  }

  connectDisconnectDbConnection(dbConnection: DataBases) {
    return this.http.post(`${this.pathPlc}/connectDisconnectDbConnection`, dbConnection);
  }

  getCancerbero() {
    return this.http.get([AppConfig.api.URL, 'configuration', 'authKey'].join('/'));
  }
}
