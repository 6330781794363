<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-edit"></i>&nbsp; Actualizar Variable/Tag!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="updateTagForm" (ngSubmit)="onSubmit(updateTagForm)">
    <div class="modal-body">
        <div class="alert alert-light pt-0 pb-0" role="alert">
            Si la edición de la variable/tag es exitosa y el PLC está conectado, se desconectará y se enlazará
            nuevamente!
            <p class="mb-0 mt-2 text-justify" style="color: #856404">
                <i class="fas fa-exclamation-triangle mr-1"></i>
                Tenga en cuenta que las operaciones del PLC, utilizan el nombre de las variables como referencia. Si
                usted
                piensa actualizar el valor de la variable (dirección de memoria en el PLC), no es necesario tomar
                precaución; Por el contario, si es el nombre de la variable el que desea actualizar, tenga en cuenta que
                debe de gestionar la actualización de las operaciones relacionadas con el nombre de esta variable
                manualmente!
            </p>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nombre de V/T: </span>
                </div>
                <input required type="text" class="form-control" formControlName="name" id="tagName">
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 140px;">Valor de V/T: </span>
                </div>
                <input required type="text" class="form-control" formControlName="tag"
                    placeholder="Espacio de memoria a leer/escribir">
            </div>
        </div>
        <hr class="my-2">
        <div *ngIf="plc.plcType === 'SIEMENS'" class="alert alert-light pt-0 pb-0 mb-0">
          <div class="mb-2">Ejemplos Valor de V/T:</div>
          <ul class ="mb-0">
            <li>Bool: Q0.0</li>
            <li>Entero: IW56</li>
            <li>Doble Entero / Real: MD20</li>
            <li>Bool: DB1,X0.0</li>
            <li>Entero: DB10,WORD10</li>
            <li>Reales: DB100,REAL4</li>
            <li>String: DB1000,S12.10 (Offset 12 / Longitud 10)</li>
          </ul>
        </div>
        <div *ngIf="plc.plcType === 'ALLEN_BRADLEY'" class="alert alert-light pt-0 pb-0 mb-0">
          <div class="mb-0 text-justify">Si el scope de la variable es global, ingrese en el campo <strong>Valor de V/T</strong>,
             el nombre del TAG como está creado en el PLC:
          </div>
          <div class="mb-2 text-center">
            <strong>CorrienteM1</strong>
          </div>
          <div class="mb-0 text-justify">Si el scope de la variable es local, ingrese en el campo <strong>Valor de V/T</strong>,
            la palabra "Progam:", luego el nombre del programa y por último el nombre del TAG anteponiendo un punto:
          </div>
          <div class="mb-2 text-center">
              <strong>Program:Motores.CorrienteM1</strong>
          </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
        <button type="submit" ngbAutofocus class="btn btn-success" [disabled]="updateTagForm.invalid">Guardar</button>
    </div>
</form>
