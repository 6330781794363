import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Plc } from 'src/app/domains/plc';

@Component({
  selector: 'app-create-operation',
  templateUrl: './create-operation.component.html',
  styleUrls: ['./create-operation.component.scss']
})
export class CreateOperationComponent implements OnInit {

  createOperationForm;
  plc: Plc;
  operationType: string;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private changeRef: ChangeDetectorRef) {
    this.createOperationForm = this.formBuilder.group({
      name: null,
      operationType: ['', Validators.required],
      action: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.operationType != 'CICLO') {
      this.createOperationForm.addControl('tag', new FormControl(null, Validators.required));
    }
    if (this.operationType == 'CONDITION') {
      this.createOperationForm.addControl('conditionDataType', new FormControl(null, Validators.required));
      this.createOperationForm.addControl('conditionOperator', new FormControl({ value: null, disabled: true }, Validators.required));
      this.createOperationForm.addControl('conditionValue', new FormControl(null, Validators.required));
    }
    this.createOperationForm.controls.operationType.value = this.operationType;
  }

  onSubmit(form) {
    this.activeModal.close(form);
  }

  dataTypeChange(event) {
    this.createOperationForm.controls.conditionOperator.enable();
  }

  actionChange(event) {
    this.changeRef.detectChanges();
  }
}
