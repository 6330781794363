import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-plc',
  templateUrl: './delete-plc.component.html'
})
export class DeletePlcComponent implements OnInit {

  plcName: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

  }

}
