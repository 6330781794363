<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-chart-line"></i>&nbsp; Monitor de variables!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6 class="font-italic text-center">{{plc.name}}</h6>
    <table class="table table-sm mb-0 text-center table-hover">
        <thead>
            <tr>
                <th scope="col" class="border-0">Variable/Tag</th>
                <th scope="col" class="border-0">Valor actual</th>
                <th scope="col" class="border-0"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let tag of plc.PlcVariables">
                <td>{{tag.name}} / {{tag.tag}}</td>
                <td>{{variables[tag.tag] === undefined ? 'NO LEÍDO' : variables[tag.tag]}}</td>
                <td *ngIf="variables[tag.tag] !== undefined">
                    <div class="spinner-grow text-success scanning-status" role="status">
                    </div>
                </td>
                <td *ngIf="variables[tag.tag] === undefined">
                    <div class="spinner-grow text-danger scanning-status" role="status">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
</div>