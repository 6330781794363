<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-edit"></i>&nbsp; Editar Operación!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="updateOperationForm" (ngSubmit)="onSubmit(updateOperationForm.value)">
    <div class="modal-body">
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nombre de operación: </span>
                </div>
                <input type="text" class="form-control" formControlName="name" placeholder="(campo opcional)">
            </div>
        </div>
        <div class="form-group" *ngIf="operation.operationType != 'CICLO'">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span *ngIf="operation.operationType == 'DIFF'" class="input-group-text"
                        style="width: 185px;">Seleccione
                        Variable/Tag: </span>
                    <span *ngIf="operation.operationType == 'CONDITION'" class="input-group-text"
                        style="width: 185px;">Variable a
                        condicionar: </span>
                </div>
                <select class="custom-select" formControlName="tag">
                    <option *ngFor="let tag of plc.PlcVariables;" [ngValue]="tag.name">{{tag.name}}/{{tag.tag}}</option>
                </select>
            </div>
        </div>
        <div *ngIf="operation.operationType == 'CONDITION'">
            <div class="form-row">
                <div class="col-md-6 form-group">
                    <label class="mb-0"><small>Tipo de dato del valor a comparar</small></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-font"></i></span>
                        </div>
                        <select class="custom-select" formControlName="conditionDataType">
                            <option value="NUMBER">NUMBER</option>
                            <option value="STRING">STRING</option>
                            <option value="BOOLEAN">BOOLEAN</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6 form-group">
                    <label class="mb-0"><small>Condicional</small></label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-terminal"></i></span>
                        </div>
                        <select class="custom-select" formControlName="conditionOperator">
                            <option value="EQUALS">Igual a</option>
                            <option value="DIFFERENT">Diferente a</option>
                            <option *ngIf="updateOperationForm.controls.conditionDataType.value == 'NUMBER'"
                                value="GREATER_THAN">Mayor que</option>
                            <option *ngIf="updateOperationForm.controls.conditionDataType.value == 'NUMBER'"
                                value="GREATER_THAN_EQUALS">Mayor o Igual a</option>
                            <option *ngIf="updateOperationForm.controls.conditionDataType.value == 'NUMBER'"
                                value="LESS_THAN">
                                Menor que</option>
                            <option *ngIf="updateOperationForm.controls.conditionDataType.value == 'NUMBER'"
                                value="LESS_THAN_EQUALS">Menor o Igual a</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span style="width: 185px;" class="input-group-text">Valor a comparar: </span>
                    </div>
                    <input type="text" class="form-control" formControlName="conditionValue"
                        placeholder="Valor a comparar!">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 185px;">Acción a realizar: </span>
                </div>
                <select class="custom-select" formControlName="action" (change)="actionChange($event)">
                    <option value="DATABASE">Exportación a base de datos</option>
                    <option value="REST">Web-service REST</option>
                </select>
            </div>
        </div>
        <app-database-exportation *ngIf="updateOperationForm.controls.action.value == 'DATABASE'"
            [parentForm]="updateOperationForm" [plcVariables]="plc.PlcVariables"
            [actionObject]="operation.actionObject">
        </app-database-exportation>
        <app-rest-exportation *ngIf="updateOperationForm.controls.action.value == 'REST'"
            [parentForm]="updateOperationForm" [plcVariables]="plc.PlcVariables"
            [actionObject]="operation.actionObject">
        </app-rest-exportation>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
        <button type="submit" ngbAutofocus class="btn btn-success"
            [disabled]="updateOperationForm.invalid">Guardar</button>
    </div>
</form>