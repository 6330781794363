import { Plc } from 'src/app/domains/plc';
import { PlcOperation } from './../../../../../domains/PlcOperation';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-update-operation',
  templateUrl: './update-operation.component.html',
  styleUrls: ['./update-operation.component.scss']
})
export class UpdateOperationComponent implements OnInit {

  operation: PlcOperation;
  plc: Plc;
  updateOperationForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private changeRef: ChangeDetectorRef) {
    this.updateOperationForm = this.formBuilder.group({
      id: undefined,
      name: null,
      operationType: ['', Validators.required],
      action: ['', Validators.required],
      PlcId: null
    });
  }

  ngOnInit(): void {
    if (this.operation.operationType != 'CICLO') {
      this.updateOperationForm.addControl('tag', new FormControl(null, Validators.required));
    }
    if (this.operation.operationType == 'CONDITION') {
      this.updateOperationForm.addControl('conditionDataType', new FormControl(null, Validators.required));
      this.updateOperationForm.addControl('conditionOperator', new FormControl(null, Validators.required));
      this.updateOperationForm.addControl('conditionValue', new FormControl(null, Validators.required));
    }
    this.updateOperationForm.patchValue(
      this.operation
    );
    this.changeRef.detectChanges();
  }

  onSubmit(form) {
    this.activeModal.close(form);
  }

  actionChange(event) {
    this.changeRef.detectChanges();
  }
}
