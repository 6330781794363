import { PlcOperation } from './../../../../../domains/PlcOperation';
import { DataBasesService } from './../../../../../services/data-bases.service';
import { PlcVariables } from './../../../../../domains/plcVariables';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataBases } from 'src/app/domains/dataBases';

@Component({
  selector: 'app-database-exportation',
  templateUrl: './database-exportation.component.html',
  styleUrls: ['./database-exportation.component.scss']
})
export class DatabaseExportationComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() plcVariables: PlcVariables[];
  @Input() actionObject: any;

  databases: DataBases[];

  mentionConfig = {
    items: [],
    triggerChar: "@",
    labelKey: 'name'
  }

  constructor(private formBuilder: FormBuilder, private databaseService: DataBasesService) {
    this.databaseService.getAllDataBasesConnections()
      .subscribe(
        (databases: DataBases[]) => {
          this.databases = databases
        }
      );
  }

  ngOnInit(): void {
    this.parentForm.controls.actionObject = this.formBuilder.group({
      databaseEntry: ['', Validators.required],
      dbExportationType: ['', Validators.required],
      query: ['INSERT INTO', [Validators.maxLength(10000), Validators.required]]
      /* Cuando incorpore la funcionalidad de Stored Procedures, es importante agregar lo 
        necesario en este mismo objeto. en el caso de los SP, se necesitan dos cosas: el nombre del SP
        y sus parametros, que en este momento 14/05/2020 pienso que puede ser un campo json String y almacenarlo así.*/
    });
    this.mentionConfig.items = this.plcVariables;

    if (this.actionObject) {
      (this.parentForm.controls.actionObject as FormGroup).addControl('id', new FormControl(undefined));
      this.parentForm.controls.actionObject.patchValue(
        this.actionObject
      );
    }

  }

  EmitEventToForm() {
    this.parentForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
