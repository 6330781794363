import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { PlcType } from 'src/app/enumerations/plc-type.enum';
import { SiemensPlcTransportType } from 'src/app/enumerations/siemens-plc-transport-type.enum';

@Component({
  selector: 'app-create-plc',
  templateUrl: './create-plc.component.html',
  styleUrls: ['./create-plc.component.scss']
})
export class CreatePlcComponent implements OnInit {

  createPlcForm;
  selectedPlcType = undefined;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.createPlcForm = this.formBuilder.group({
      name: ['', Validators.required],
      plcType: ['', Validators.required],
      plcObject: undefined
    });
  }

  ngOnInit(): void {
  }

  onSubmit(form) {
    //Realizo una validación final sobre los datos numéricos para evitar cometer errores en la data.
    if ((form.plcObject.hasOwnProperty('timeOut')) && form.plcObject.timeOut == '') {
      delete form.plcObject.timeOut
    }
    if (form.plcObject.cycleTime == '') {
      delete form.plcObject.cycleTime
    }
    this.activeModal.close(form);
  }

  plcTypeChange(event) {
    switch (event.target.value) {
      case PlcType.SIEMENS:
        this.createPlcForm.controls.plcObject = this.formBuilder.group({
          transport: SiemensPlcTransportType.ETHERNET,
          address: ['', [Validators.required, Validators.pattern("(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)")]],
          port: ['', Validators.required],
          rack: ['', Validators.required],
          slot: ['', Validators.required],
          timeOut: '',
          cycleTime: ['', Validators.min(500)],
          type: ['', Validators.required],
          autoReconnect: [false, Validators.required]
        })
        break;
      case PlcType.ALLEN_BRADLEY:
        this.createPlcForm.controls.plcObject = this.formBuilder.group({
          address: ['', [Validators.required, Validators.pattern("(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)")]],
          slot: ['', Validators.required],
          cycleTime: ['', Validators.min(500)],
          type: ['', Validators.required],
          autoReconnect: [false, Validators.required]
        })
        break;
    }
    this.EmitEventToForm();
    this.selectedPlcType = event.target.value;

  }

  EmitEventToForm() {
    this.createPlcForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
