<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-trash-alt"></i>&nbsp; Elminar conexión BD!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-warning text-justify" role="alert">
        Esta acción, eliminará la conexión de base de datos configurada: <strong>{{dbConnectionName}}</strong>. También
        eliminará en secuencia, todas las operaciones de exportación (PLC) de información que estén usando esta
        conexión.
        Asegurese de que la acción que está a punto de realzar sea adecuada.
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="activeModal.close()">Eliminar</button>
</div>