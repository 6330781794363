<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-trash-alt"></i>&nbsp; Elminar Operación!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-warning" role="alert">
        Esta acción, eliminará la operación: <strong>{{operation.name}}</strong> del PLC junto con todas sus
        configuraciones asociadas. La acción asociada a la operación dejará de ser ejecutada en el siguiente ciclo de
        lectura del PLC.
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="activeModal.close()">Eliminar</button>
</div>