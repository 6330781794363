<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-database"></i>&nbsp; Agregar Base de Datos!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="createDbForm" (ngSubmit)="onSubmit(createDbForm.value)" (keyup)="resetConnectionFlag()">
    <div class="modal-body">
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nombre de la conexión: </span>
                </div>
                <input required type="text" class="form-control" formControlName="name"
                    placeholder="Ingrese nombre personalizado!">
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 195px;">Tipo de BD: </span>
                </div>
                <select class="custom-select" formControlName="type" (change)="dbTypeChange($event)">
                    <option value="MSSQL">Microsoft SQL SERVER</option>
                </select>
            </div>
        </div>
        <div class="form-group" *ngIf="selectedDbType === 'MSSQL'">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Versión: </span>
                </div>
                <select class="custom-select" formControlName="version" (change)="EmitEventToForm()">
                    <option value="7_4" selected>SQL Server 2012/2014/2016/2017</option>
                    <option value="7_3_A">SQL Server 2008 R2</option>
                    <option value="7_3_B">SQL Server 2008</option>
                    <option value="7_2">SQL Server 2005</option>
                    <option value="7_1">SQL Server 2000</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-globe-americas"></i></span>
                    </div>
                    <input required type="text" class="form-control" formControlName="host"
                        placeholder="Ingrese el Host/IP">
                </div>
            </div>
            <div class="form-group col-md">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-ethernet"></i></span>
                    </div>
                    <input type="number" class="form-control" formControlName="port" placeholder="Puerto" id="port">
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input required type="text" class="form-control" formControlName="user"
                        placeholder="Ingrese usuario">
                </div>
            </div>
            <div class="form-group col-md">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                    </div>
                    <input type="text" class="form-control" formControlName="password" placeholder="Ingrese contraseña">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-database"></i></span>
                </div>
                <input required type="text" class="form-control" formControlName="database"
                    placeholder="Nombre de la base de datos">
            </div>
        </div>
        <div class="text-center">
            <button *ngIf="createDbForm.valid" type="button" class="btn btn-info" (click)="testDbConnection()">
                <i class="fas fa-bolt mr-2"></i>
                Probar conexión</button>
        </div>
        <div [class]="testResult.class" class="alert mb-0 text-justify" *ngIf="testResult">
            {{testResult.toastr}}
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
        <button type="submit" ngbAutofocus class="btn btn-success"
            [disabled]="(createDbForm.invalid) || (testConnection === false)">Guardar</button>
    </div>
</form>