<hr>
<div [formGroup]="parentForm">
    <div formGroupName="actionObject">
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 185px;">Seleccione la BD: </span>
                </div>
                <select class="custom-select" formControlName="databaseEntry" (change)="EmitEventToForm()">
                    <option *ngFor="let db of databases;" [ngValue]="db.id + '-' + db.type">{{db.name}}</option>
                </select>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 185px;">Tipo de sentencia: </span>
                </div>
                <select class="custom-select" formControlName="dbExportationType" (change)="EmitEventToForm()">
                    <option value="QUERY">Exportación por Query</option>
                    <!-- <option value="SP">Exportación por Stored Procedure</option> -->
                </select>
            </div>
        </div>
        <div *ngIf="parentForm.controls.actionObject.get('dbExportationType').value == 'QUERY'"
            (keyup)="EmitEventToForm()">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Query: </span>
                    </div>
                    <textarea class="form-control" formControlName="query" rows="3" placement="right"
                        [ngbPopover]="queryInfo" popoverTitle="Querys" popoverClass="infoQueryPopOver" container="body"
                        [mentionConfig]="mentionConfig"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Esta es la información acerca del QUERY para las DBS -->
<ng-template #queryInfo>
    <div class="text-justify">
        <p>Este campo, le permite especificar la sentenia de tipo INSERT que se ejecutará al momento de ejecutar la
            acción que está configurando.</p>
        <div class="bd-callout bd-callout-warning">
            <h6>Sintaxis</h6>
            <p class="mb-0">
                <code class="mr-1">INSERT</code><code>INTO</code> nombre_tabla (columna1, columna2, columna3, ...)
                <code>VALUES</code> (valor1, valor2, valor3, ...);
            </p>
        </div>
        <p>Cuando escriba los valores a insertar en cada columna, inicie con <code>@</code> para habilitar el
            autocompletado; De esta forma, cuando el ciclo de lectura se lleve a cabo, se puedan insertar los valores
            leídos por el PLC.</p>
        <div class="bd-callout bd-callout-warning">
            <h6>Ejemplo:</h6>
            <p class="mb-0">
                <code class="mr-1">INSERT</code><code>INTO</code> variables (columna1, columna2, columna3, ...)
                <code>VALUES</code> (@variable1, @variable2, @variable3, ...);
            </p>
        </div>
        <p>Asegurese que las variables que intenta guardar tengan el tipo de datos correcto para la columna a la que la
            relacionará.</p>
        <p>Tenga en cuenta, que en caso de que el Query esté mal escrito, el sistema simplemente lo ignorará. Asegurese
            de probar el query primero de forma ISOLADA en su base de datos antes de ingresarlo a TOT, esto le evitará
            problemas en el futuro.</p>
        <p>Nota adicional: las sentencias SELECT, no tendrán efecto sobre las operaciones, puede realizar INSERT, UPDATE
            y DELETES también!.</p>

    </div>
</ng-template>