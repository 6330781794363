<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-list-alt"></i>&nbsp; Historial de logs!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6 class="font-italic text-center">{{plc.name}}</h6>
    <div class="list-group" *ngIf="plcLogs.length > 0">
        <div class="list-group-item text-justify pt-1 pb-2" *ngFor=" let log of plcLogs">
            <div class="d-flex w-100 justify-content-between">
                <strong class="mb-1">
                    <i *ngIf="log.logType == 'ERROR'" class="fas fa-exclamation-triangle text-danger"></i>
                    <i *ngIf="log.logType == 'INFO'" class="fas fa-info-circle text-info mr-1"></i>
                    <i *ngIf="log.logType == 'WARN'" class="fas fa-exclamation-triangle text-warning"></i>
                    {{log.logType}}
                </strong>
                <small>{{log.createdAt | date:'medium'}}</small>
            </div>
            <p class="mb-0" style="line-height: 1rem;">{{log.logMessage}}</p>
        </div>
    </div>
    <div class="alert alert-light text-center mb-0" role="alert" *ngIf="plcLogs.length == 0">
        El historial de logs esta vacío!
    </div>
</div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
    <button *ngIf="plcLogs.length > 0" type="button" class="btn btn-outline-danger" (click)="deleteAllPlcLogs()">Borrar
        historial</button>
</div>